/**
 * @module Rgpd
 * @description 
 * Module for managing cookies.
 * Add .rgpd-btn-manage to link to open the manage popin
 * 
 * @callback onexec(cat) called only if a categorie is enabled. 
 * @param cat is the id of the cookie ex 'stats'
 * @var strictmode if false, execute onexec event if no consent action in modal alert
 * @var cookieName name of the rgpd cookie 
 * @var cookieDuration duration of the rgpd cookie
 * 
 */



const Rgpd = onexec => {
    const cookieName = 'rgpd';
    const cookieDuration = 30;
    const strictmode = true;
    const modal = document.querySelector('.rgpd-modal');
    const manage = document.querySelector('.rgpd-manage');
    const manage_box = manage.querySelector('.box');
    const btn_details = document.querySelectorAll('.rgpd-btn-detail');
    const btn_accept = document.querySelector('.rgpd-btn-accept');
    const btn_refuse = document.querySelector('.rgpd-btn-refuse');
    const btn_manage = document.querySelectorAll('.rgpd-btn-manage');
    const btn_save = document.querySelector('.rgpd-btn-save');
    const checkboxes = manage.querySelectorAll('input[type="checkbox"]');
    var cats = {};
    const Cookie = {
        create(name, value, days) {
            var expires = '';
            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toGMTString();
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        },
        read(name) {
            const nameEQ = name + '=';
            const cas = document.cookie.split(';');
            for (var i = 0; i < cas.length; i++) {
                var ca = cas[i];
                while (ca.charAt(0) === ' ') ca = ca.substring(1, ca.length);
                if (ca.indexOf(nameEQ) === 0) return ca.substring(nameEQ.length, ca.length);
            }
            return null;
        },
        erase(name) {
            var hostname = document.location.hostname;
            if (hostname.indexOf('www.') === 0) {
                hostname = hostname.substring(4);
            }
            //hostname = hostname.substr(hostname.indexOf('.') + 1); //if subdomains
            document.cookie = name + '=; domain=.' + hostname + '; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/';
            document.cookie = name + '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/';
        },
        eraseUnused() {
            for (let checkbox of checkboxes) {
                if (checkbox.checked === false) {
                    checkbox.getAttribute('data-cookies').split(',').forEach(cookie => Cookie.erase(cookie));
                }
            }
        },
    };
    var consent = Cookie.read(cookieName) ? true : false;

    // fire event onexec if cat == true
    const _execute = () => {
        for (let key in cats) {
            if (cats[key]) {
                if(typeof onexec === 'function') onexec(key);
            }
        }
    }

    // set cookie obj status
    const _status = () => checkboxes.forEach(item => cats[item.value] = item.checked ? true : false )

    // set consent cookie (rgpd)
    const _consent = () => {
        consent = true;
        modal.classList.remove("active");
        Cookie.create(cookieName, JSON.stringify(cats), cookieDuration);
    }

    // accept in alert
    const _accept = () => {
        _status();
        _execute();
        _consent();
    }

    // refuse in alert
    const _refuse = () => {
        checkboxes.forEach(checkbox => checkbox.checked = false)
        _status();
        _consent();
    }

    // Save modification
    const _save = () => {
        _status();
        if(!consent) _execute();
        _consent();
        Cookie.eraseUnused();
    }

    // disable window scroll when modal open
    const disableScroll = () => window.scrollTo(0, scrollTop);

    // init or load cookie obj
    if (consent === true) cats = JSON.parse(Cookie.read(cookieName));
    else if (!strictmode){
        _status();
        checkboxes.forEach(checkbox => cats[checkbox.value] = true);
        _execute();
    } else {
        checkboxes.forEach(checkbox => cats[checkbox.value] = true);
    }

    // init checkboxes checked
    for(let key in cats){
        for(let checkbox of checkboxes){
            if(checkbox.value == key) checkbox.checked = cats[key];
        }
   }

    btn_details.forEach(btn => btn.onclick = () => btn.classList.toggle("active"))

    btn_accept.onclick = () => _accept();

    btn_refuse.onclick = () => _refuse();

    consent == true ? _execute() : modal.classList.add("active");

    // btn manage to open modial manage
    btn_manage.forEach(btn => {
        btn.onclick = e =>{
            e.preventDefault();
            _open()
        }  
    });

    // popin manage
    const root = document.documentElement || window;
    const clicktouch = ('ontouchstart' in root) ? "touchstart" : "click";
    const btn_close = document.querySelector(".rgpd-btn-close");
    manage_box.appendChild(btn_close);
    const link = document.querySelector(".rgpd-link");
    var scrollTop;
        
    const _clickoutside = e => !manage_box.contains(e.target) && _close();

    const _open = () => {
        _status();
        modal.classList.remove("active");
        manage.classList.add("open");
        manage.addEventListener("animationend", e => window.addEventListener(clicktouch, _clickoutside), {once: true});
        scrollTop = window.pageYOffset || window.scrollY;
        window.addEventListener('scroll', disableScroll);
    }

    const _close = e => {
        if(!consent) modal.classList.add("active");
        manage.classList.add('close');
        window.removeEventListener(clicktouch, _clickoutside, false)
        window.removeEventListener('scroll', disableScroll);
        btn_details.forEach(btn => btn.onclick = () => btn.classList.toggle("active"))
        manage.addEventListener("animationend", e => {
            manage.classList.remove("open");
            manage.classList.remove('close');
        }, {once: true});
    }

    // manage popin link if exist
    if(link){
        link.onclick = e => {
            e.stopPropagation();
            e.preventDefault();
            _open();
        };
    }

    btn_save.onclick = () => _save();
    btn_save.addEventListener('click', _close);
    btn_close.onclick = e => _close(e);
};

export default Rgpd;
