
function PopinMedia (id) {
    const popinMedia = document.createElement("div");
    popinMedia.className = 'popinMedia';
    popinMedia.innerHTML = `
    <div class="window">
        <header><button class="btn-close"></button></header> 
        <iframe width="560" height="315" src="https://www.youtube.com/embed/${id}" frameborder="0" allow="autoplay; encrypted-media;" allowfullscreen></iframe> 
    </div>`;
    
    document.body.appendChild(popinMedia);
    const iframe = popinMedia.querySelector('iframe');
    iframe.onload = () => popinMedia.classList.add('show');
    
    const btn_close = popinMedia.querySelector('.btn-close');
    btn_close.onclick = () => {
        popinMedia.classList.add('hide');
        popinMedia.addEventListener('animationend', () => {
            popinMedia.classList.remove('hide');
            popinMedia.classList.remove('show');
            popinMedia.remove();
        }, {once:true})
    }
}

export default PopinMedia;
